import {
  capitalizeFirstLetter,
  fISOToReadableDay,
  fISOToReadableNoTimeShort,
  fISOToTime,
  fNumber,
  fNumberWithCommas,
  fPercent,
  fPercentWithThreshold,
  fReadableCurrency,
  fRoundedNumberWithCommas,
  removeTextAfterFirstUnderScore,
  snakeCaseToProper,
} from '@autone/utils';
import { type i18n as I18nType } from 'i18next';

export const addFormatters = (i18n: I18nType) => {
  i18n.services.formatter?.add('toLowerCase', (value) => value.toLowerCase());
  i18n.services.formatter?.add('fNumber', (value, lng, options) => {
    return fNumber(value, options.format);
  });
  i18n.services.formatter?.add('fNumberWithCommas', (value) =>
    fNumberWithCommas(value),
  );
  i18n.services.formatter?.add('fISOToReadableNoTimeShort', (value) =>
    fISOToReadableNoTimeShort(value),
  );
  i18n.services.formatter?.add('fPercent', (value) => fPercent(value));
  i18n.services.formatter?.add('fReadableCurrency', (value, lng, options) => {
    return fReadableCurrency(value, options.currency || 'EUR');
  });
  i18n.services.formatter?.add('fRoundedNumberWithCommas', (value) => {
    return fRoundedNumberWithCommas(value);
  });
  i18n.services.formatter?.add('removeTextAfterFirstUnderScore', (value) => {
    return removeTextAfterFirstUnderScore(value);
  });
  i18n.services.formatter?.add('mathRound', (value) =>
    Math.round(value).toString(),
  );
  i18n.services.formatter?.add('snakeCaseToProper', (value) => {
    return snakeCaseToProper(value);
  });
  i18n.services.formatter?.add('capitalizeFirstLetter', (value) => {
    return capitalizeFirstLetter(value);
  });
  i18n.services.formatter?.add(
    'fPercentWithThreshold',
    (value, lng, { thresholdData }) => {
      return fPercentWithThreshold(value, {
        value: thresholdData.threshold,
        label: thresholdData.thresholdLabel,
      });
    },
  );
  i18n.services.formatter?.add('fISOToReadableDay', (value) => {
    return fISOToReadableDay(value);
  });
  i18n.services.formatter?.add('fISOToTime', (value) => {
    return fISOToTime(value);
  });
};
